/* eslint-disable no-empty */
/* eslint-disable unicorn/prefer-optional-catch-binding */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import {
  Transition,
  Dialog,
  DialogBackdrop,
  TransitionChild,
  DialogTitle
} from '@headlessui/react';
import { ShoppingCartIcon } from '@heroicons/react/solid';
import usePrice from '../../hooks/usePrice';
import GrayButton from '../GrayButton';
import SecondaryButtonBig from '../SecondaryButtonBig';
import { ICartProduct, ICart } from '../../models/cart';
import {
  clearGiftProduct,
  removeProduct,
  updateProduct
} from '../../fetchers/cart';
import { getLangCountry } from '../../helpers/languages';
import useQueryParams from '../../hooks/useQueryParams';
import { SET_SESSION_CART } from '../../context/types';
import StyledImage from '../StyledImage';
import visa from '../../public/img/visa-grey.svg';
import mastercard from '../../public/img/mastercard-gray.svg';
import paypal from '../../public/img/paypal-grey.svg';
import postepay from '../../public/img/postepay-gray.svg';
import amex from '../../public/img/amex-gray.svg';
import CartProduct from './CartProduct';
import { useAlert } from '../../context/AlertProvider';
import { useTranslation } from 'react-i18next';
import MiniSpinner from '../MiniSpinner';
import { useSelector, useStore } from 'react-redux';
import { IGlobalState } from '../../context/reducer';
import { getToken } from '../../helpers/auth';
import * as pixelFB from '../../helpers/pixelEvents';
import BannerProduct from './BannerProduct';
import FreeCartProduct from './FreeCartProduct';
import ImgPromoBanner from '../../public/img/promo-banner-img.jpg';
import Link from 'next/link';
import dynamic from 'next/dynamic';
const WidgetScalapay = dynamic(() => import('../WidgetScalapay'), {
  ssr: false
});

export default function MenuCart({ isSearchOpen }: { isSearchOpen: boolean }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formatPrice] = usePrice();
  const [query] = useQueryParams();
  const { t } = useTranslation();
  const { lang, country } = getLangCountry(query);
  const alert = useAlert();
  const store = useStore();
  const { session = {} } = useSelector<IGlobalState, IGlobalState>(
    state => state || {}
  );
  const {
    products = [],
    gift_products,
    total,
    subtotal,
    remaining = 0,
    shipping = 0
  } = (session as ICart).cart || {};

  const token = getToken();
  const CHECKOUT_URL = `${process.env.NEXT_PUBLIC_CHECKOUT_URL}?session_token=${token}`;

  const sendCheckoutEv = () => {
    //PIXEL EVENT Checkout
    // pixelFB.event({ eventName: 'InitiateCheckout' });
  };

  const onChangeQty = async (product: ICartProduct, qty: number) => {
    try {
      setLoading(true);
      const cart = await updateProduct({
        ...getLangCountry(query),
        token,
        sku: product['product-sku'],
        quantity: qty
      });
      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const onRemoveProduct = async (product: ICartProduct) => {
    try {
      setLoading(true);
      const cart = await removeProduct({
        ...getLangCountry(query),
        token,
        sku: product['product-sku']
      });

      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const onClearProduct = async (promotion_id: number) => {
    try {
      setLoading(true);
      const cart = await clearGiftProduct({
        ...getLangCountry(query),
        token,
        promotion_id: promotion_id
      });

      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative">
        <button
          id="menu-cart"
          className="bg-white rounded-full flex text-sm focus:outline-none"
          onClick={() => setOpen(true)}
          disabled={isSearchOpen}
        >
          <div className="p-2 text-black hover:text-gray-500 flex relative">
            <span className="sr-only">Cart</span>
            <ShoppingCartIcon className="w-6 h-6" aria-hidden="true" />
            <span className="block border-2 border-white">
              <span className="h-5 w-5 rounded-md bg-primary flex items-center justify-center text-black font-bold text-9px">
                {products.length}
              </span>
            </span>
          </div>
        </button>
      </div>
      <Transition show={open}>
        <Dialog
          as="div"
          auto-reopen="true"
          className="fixed inset-0 ovw-screen max-w-mderflow-hidden z-40"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <TransitionChild
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <TransitionChild
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="h-full w-screen max-w-md md:max-w-4xl">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="flex-1 py-12 overflow-y-auto px-4 sm:px-12">
                      <div className="flex items-start justify-between">
                        <DialogTitle className="text-5xl font-bold text-gray-900 font-sans">
                          {t('CART')}
                        </DialogTitle>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="-m-2 py-3 px-4 rounded-md text-sm hover:text-gray-500 focus:outline-none bg-gray-100"
                            onClick={() => setOpen(false)}
                          >
                            {t('CLOSE')}
                          </button>
                        </div>
                      </div>

                      <hr className="mb-6 mt-2" />

                      <div>
                        <div className="flow-root">
                          <div className="mb-4 space-y-6">
                            {/* TODO: @andrea - Rimuovere */}
                            {/* <div className="bg-primary md:flex md:space-y-0 rounded-lg">
                              <div className="flex-1 flex flex-col justify-center px-4 py-4 lg:py-6">
                                <div>
                                  <div className="bg-black text-primary uppercase font-bold inline-flex text-xs px-2 py-1 rounded-sm mb-3">
                                    {t('PROMO_BANNER.LABEL')}
                                  </div>
                                </div>
                                <p className="text-black font-sans font-bold text-2xl mb-1 text-center lg:text-left">
                                  {t('PROMO_BANNER.TITLE')}
                                </p>
                              </div>

                              <div className="flex justify-center items-center lg:pl-24">
                                <img
                                  src={ImgPromoBanner.src}
                                  alt="Banner product"
                                  className="w-auto h-40"
                                />
                              </div>
                            </div> */}

                            {gift_products?.banners?.map((banner, idx) =>
                              Object.keys(banner).length > 0 &&
                              Object.keys(banner).includes(lang) ? (
                                <BannerProduct
                                  key={idx}
                                  onClickFunction={() => setOpen(false)}
                                  {...banner[lang]}
                                />
                              ) : (
                                <></>
                              )
                            )}
                          </div>
                          {products.length > 0 ? (
                            <React.Fragment>
                              <ul className="-my-6">
                                {gift_products?.products?.map(product => (
                                  <li
                                    key={product['product-sku']}
                                    className="py-3 flex"
                                  >
                                    <FreeCartProduct
                                      product={product}
                                      onClearProduct={onClearProduct}
                                    />
                                  </li>
                                ))}
                                {products.map((product: ICartProduct) => (
                                  <li
                                    key={product['product-sku']}
                                    className="py-6 flex"
                                  >
                                    <CartProduct
                                      product={product}
                                      onChangeQty={onChangeQty}
                                      onRemoveProduct={onRemoveProduct}
                                    />
                                  </li>
                                ))}
                              </ul>
                            </React.Fragment>
                          ) : (
                            <div className="flex justify-center items-center h-full">
                              <p>{t('EMPTY_CART')}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-10 px-4 sm:px-12">
                      <div className="mb-3 flex flex-col">
                        <div className="flex justify-between text-sm font-normal text-gray-400 mb-4">
                          <p>{t('SUBTOTAL')}</p>
                          <p>{formatPrice(subtotal)}</p>
                        </div>
                        <div className="flex justify-between text-sm font-normal text-gray-400 mb-4">
                          <p>{t('SHIPPING_FEES')}</p>
                          <p>{formatPrice(shipping)}</p>
                        </div>

                        {Number(remaining) > 0 ? (
                          <div className="bg-gray-100 p-2 inline-block ml-auto rounded-md">
                            <p className="text-sm text-gray-400 text-right">
                              {t('CART_SHIPPING_FREE_MESSAGE_1')}{' '}
                              <b className="font-bold text-black">
                                {formatPrice(Math.max(0, remaining))}
                              </b>{' '}
                              {t('CART_SHIPPING_FREE_MESSAGE_2')}
                            </p>
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col mb-4">
                        <div className="flex justify-between text-base font-bold text-gray-900">
                          <p>{t('TOTAL')}</p>
                          <p className="inline-flex items-center">
                            {loading && <MiniSpinner />}{' '}
                            {products.length > 0
                              ? formatPrice(total)
                              : formatPrice(0)}
                          </p>
                        </div>
                        <div className="text-right">
                          <WidgetScalapay lang={lang} amount={total} />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <Link href={CHECKOUT_URL}>
                          <SecondaryButtonBig
                            text={t('GO_TO_CHECKOUT')}
                            onClick={sendCheckoutEv}
                          />
                        </Link>
                        <GrayButton
                          text={t('CONTINUE_SHOPPING')}
                          onClick={() => setOpen(false)}
                        />
                      </div>
                      <p className="mt-6 text-sm text-center text-gray-500">
                        {t('PAY_SECURE')}
                      </p>
                      <div className="flex justify-between mt-3 w-2/3 mx-auto">
                        <StyledImage
                          src={visa}
                          className="w-12"
                          alt="visa logo"
                        />
                        <StyledImage
                          src={mastercard}
                          className="w-12"
                          alt="mastercard logo"
                        />
                        <StyledImage
                          src={paypal}
                          className="w-12"
                          alt="paypal logo"
                        />
                        <StyledImage
                          src={postepay}
                          className="w-12"
                          alt="postepay logo"
                        />
                        <StyledImage
                          src={amex}
                          className="w-12"
                          alt="american express logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
