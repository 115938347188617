import usePrice from '../hooks/usePrice';
import { IProductList } from '../models/product';
import localizedRoute from '../utils/localizedRoute';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { classNames } from '../helpers/classes';
import placeholder from '../public/img/placeholder.svg';
import useWindowSize from '../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import StyledImage from './StyledImage';
import xtremeBadge from '../public/img/xp_badge.png';
import Link from 'next/link';

const ProductTile = ({
  product,
  onClick,
  ui
}: {
  product: IProductList;
  onClick?: any;
  ui?: string;
}) => {
  const { t } = useTranslation();
  const [formatPrice] = usePrice();
  const {
    name,
    slug = '',
    gallery,
    price,
    badges = [],
    extreme = false
  } = product;
  const { images = [] } = gallery || {};
  const [width] = useWindowSize();
  const link = localizedRoute(slug);

  const colorImages = width >= 640 && width <= 1024 ? 2 : 4;

  const galleryImages = (
    images && images.length > 0 ? images : [placeholder]
  ).slice(0, colorImages);

  const plusImage = (images || []).length - (galleryImages || []).length;

  const getProductPrice = () => {
    if (price.min && price.max) {
      if (Number(price.min) !== Number(price.max)) {
        return `${formatPrice(price.min)} - ${formatPrice(price.max)}`;
      }

      return formatPrice(price.min);
    } else if (price.from) {
      return `${t('STARTING_FROM')}${formatPrice(price.from)}`;
    } else if (!price.sale_price) {
      return formatPrice(price.price);
    } else {
      return (
        <>
          <span className="line-through	opacity-60">
            {formatPrice(price.price)}
          </span>
          <span className="font-bold"> {formatPrice(price.sale_price)}</span>
        </>
      );
    }
  };
  return (
    <div className="group relative">
      <div className="w-full  rounded-md overflow-hidden lg:aspect-none">
        <TabGroup as="div" className="flex flex-col-reverse">
          <div className="py-2 mt-2 w-full max-w-2xl mx-auto lg:max-w-none">
            <TabList className="flex space-x-2">
              {galleryImages.map((image, i) => (
                <Tab
                  key={i}
                  onMouseEnter={(ev: any) => {
                    ev.target.click();
                  }}
                  className="relative h-14 w-14 xl:w-14 xl:h-14 bg-white rounded-md flex items-center justify-center text-sm font-bold uppercase opacity-80 cursor-pointer"
                >
                  {({ selected }) => (
                    <>
                      <div className="absolute inset-0 rounded-md overflow-hidden">
                        <img
                          src={image}
                          alt={name}
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div
                        className="ring-transparent absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Tab>
              ))}
              {plusImage > 0 && (
                <Tab className="bg-modalgrey opacity-60 font-bold rounded-md 14 w-14 xl:w-14 xl:h-14 pointer-events-none">
                  + {plusImage}
                </Tab>
              )}
            </TabList>
          </div>

          <TabPanels className="w-full aspect-w-1 aspect-h-1 lg:h-60">
            {galleryImages.map((image, i) => (
              <TabPanel key={i}>
                <Link href={link} onClick={onClick}>
                  <div className="flex flex-wrap gap-2 absolute right-4 top-4">
                    {badges.map((badge, idx) => (
                      <div
                        key={idx}
                        className={classNames(
                          extreme ? 'bg-orange' : 'bg-primary',
                          'px-3 py-2 bg-primary rounded-md text-xs text-black'
                        )}
                      >
                        <p className="font-bold">{badge}</p>
                      </div>
                    ))}
                  </div>
                  {extreme ? (
                    <div className="flex flex-wrap gap-2 absolute left-4 top-4">
                      <StyledImage src={xtremeBadge} alt="Xtreme badge" />
                    </div>
                  ) : (
                    <></>
                  )}
                  <img
                    src={image}
                    alt={name}
                    className="bg-white w-full h-full object-center object-cover sm:rounded-lg cursor-pointer"
                  />
                </Link>
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
      <div className="mt-4 flex-column justify-between">
        <div>
          <h3
            className={`text-sm font-bold opacity-60' ${
              ui ? 'text-white' : 'text-black'
            }`}
          >
            <Link href={link} onClick={onClick}>
              {name}
            </Link>
          </h3>
        </div>

        <p
          className={`text-sm font-bold mt-1 ${
            ui ? 'text-orange' : 'text-primary'
          }`}
        >
          {getProductPrice()}
        </p>
      </div>
    </div>
  );
};

export default ProductTile;
