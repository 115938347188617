import React from 'react';
import Link from 'next/link';

const PrimaryButtonSmall = ({ text, href }: { text: any; href: string }) => {
  return (
    <Link
      href={href}
      className="bg-primary text-black px-4 py-2 rounded-sm uppercase font-normal w-max flex justify-center items-center"
    >
      {text}
    </Link>
  );
};

PrimaryButtonSmall.defaultProps = {
  text: '',
  href: '#'
};

export default PrimaryButtonSmall;
