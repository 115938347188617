import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchColors } from '../../fetchers/mapping';
import { ICartProduct, ICartProductGift } from '../../models/cart';
import localizedRoute from '../../utils/localizedRoute';
import MiniSpinner from '../MiniSpinner';
import Link from 'next/link';

const FreeCartProduct = ({
  product,
  onClearProduct
}: {
  product: ICartProductGift;
  onClearProduct: (promotion_id: number) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const colorsCodeToLabel = fetchColors();

  const getColorLabel = (color_code: string) => {
    const { color = '' } = colorsCodeToLabel[color_code] || {};
    return color;
  };

  const clearProduct = async () => {
    setLoading(true);
    await onClearProduct(Number.parseInt(product.promotion || '0'));
    setLoading(false);
  };
  if (!product || !product.selected || product.selected.length === 0)
    return <></>;
  return (
    <>
      <div className="flex-shrink-0 w-16 h-16 lg:w-24 lg:h-24 border border-gray-200 rounded-md overflow-hidden">
        <img
          src={product.image}
          alt={product.name}
          className="w-full h-full object-center object-cover"
        />
      </div>

      <div className="ml-2 lg:ml-4 flex-1 flex flex-col">
        <div className="text-sm font-bold text-gray-900">
          <h3>
            <Link href={product.slug ? localizedRoute(product.slug) : ''}>
              {product.name}
            </Link>
          </h3>

          <p className="mt-1 text-xs text-gray-500">
            {product.size} | {getColorLabel(product.color)}
          </p>
        </div>
        <div className="flex-1 flex items-end justify-between text-sm">
          <p className="text-sm mt-3 bg-lightblue text-primary p-2 rounded-md">
            {t('PRODUCT_FREE')}
          </p>
          {product.products &&
            product.products.length > 1 &&
            product.selected &&
            product.selected.length > 0 && (
              <div className="flex">
                <button
                  type="button"
                  className="font-bold text-primary inline-flex items-center mb-1"
                  onClick={clearProduct}
                >
                  {loading && <MiniSpinner />}
                  {t('REMOVE')}
                </button>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default FreeCartProduct;
